.overlayStyle {
    transform: translateY(-100%);
    height: 85%;
    opacity: .3;
    background: grey;
}
.overlayStyle > div > svg {
    color: white;
    width: 30px;
    height: 30px;
}
.overlayStyle > div {
    align-items: center;
    align-content: center;
    height: 100%;
}